import self from "../img/self.png";
import mock2 from "../img/mock2.png";
import mock3 from "../img/mock3.png";
import mock4 from "../img/mock4.png";
import mock5 from "../img/mock5.png";

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
export const info = {
  firstName: "Cole",
  lastName: "Friedlaender",
  initials: "cf",
  position: "a Software Developer and Student at TUM",
  selfPortrait: self,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`,
  baseColor: colors[0],
  miniBio: [
    {
      emoji: "☕",
      text: "Start upright",
    },
    {
      emoji: "🌎",
      text: "Based in Munich, Germany",
    },
    {
      emoji: "🏫",
      text: "Studying Information Systems at TUM",
    },
    {
      emoji: "💼",
      text: "Software Developer at RTL Deutschland",
    },
    {
      emoji: "📧",
      text: "cole.friedlaender@tum.de",
    },
  ],
  socials: [
    {
      link: "https://instagram.com",
      icon: "fa fa-instagram",
      label: "instagram",
    },
    {
      link: "https://github.com/colefriedlaender",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://de.linkedin.com/in/cole-friedlaender-968a82203",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
  ],
  bio: "Hello! I'm Cole, I am 20 years old and a software developer at RTL Deutschland. I am studying Information Systems at the Technical University of Munich, and I believe artificial intelligence will inevitably rule us all one day.",
  skills: {
    proficientWith: [
      "javascript",
      "react",
      "git",
      "typescript",
      "java",
      "aws",
      "power platform",
      "figma",
    ],
    exposedTo: ["camunda", "python", "expo"],
  },
  hobbies: [
    {
      label: "hockey",
      emoji: "🏑",
    },
    {
      label: "theater",
      emoji: "🎭",
    },
    {
      label: "sailing",
      emoji: "⛵️",
    },
    {
      label: "coding",
      emoji: "👨🏽‍💻",
    },
  ],
  portfolio: [
    {
      title: "Clock Watch",
      live: "https://paytonpierce.dev",
      source: "https://github.com/colefriedlaender",
      image: mock2,
    },
    {
      title: "To Do List",
      live: "https://paytonpierce.dev",
      source: "https://github.com/colefriedlaender",
      image: mock3,
    },
    {
      title: "Gradient Creator",
      live: "https://paytonpierce.dev",
      source: "https://github.com/colefriedlaender",
      image: mock4,
    },
    {
      title: "Media Player",
      live: "https://paytonpierce.dev",
      source: "https://github.com/colefriedlaender",
      image: mock5,
    },
  ],
};
